<template>
    <v-container fluid>
        <v-row>
            <v-col class="pt-0 pb-0">
                <trev-job-reports-quick-links-bar></trev-job-reports-quick-links-bar>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <trev-jobs-table :isGlobal="true" :defaultStatus="''">
                </trev-jobs-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import trevJobsTable from '../../../../components/jobs/trev-jobs-table.vue'
import TrevJobReportsQuickLinksBar from '../../../../components/reports/trev-job-reports-quick-links-bar.vue'
export default {
    components: { trevJobsTable, TrevJobReportsQuickLinksBar },

}
</script>

<style>

</style>




<!-- <template>
    <trev-cool-table @loaded="loadedData" :headers="headers" :courier="$courier.Jobs">
        <template slot="title">
            <span class="mr-4">
                <v-icon class="mr-2">mdi-rocket</v-icon> All Jobs
            </span>
            <span class="primary--text">
                {{ (selectedStatus ? selectedStatus : 'All') }}
            </span>
            <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="selectedStatus = ''">
                        <v-list-item-title>
                            All
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="selectedStatus = 'Open'">
                        <v-list-item-title>
                            Open
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="selectedStatus = 'In Progress'">
                        <v-list-item-title>
                            In Progress
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="selectedStatus = 'On Hold'">
                        <v-list-item-title>
                            On Hold
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="selectedStatus = 'Complete'">
                        <v-list-item-title>
                            Complete
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip :color="getStatusColour(item)">{{ item.status }}</v-chip>
        </template>
        <template v-slot:item.poNumber="{ item }">
            <v-chip color="info" v-if="item.poNumber">
                <v-icon class="mr-2">
                    mdi-pound
                </v-icon>
                {{ item.poNumber }}</v-chip>
        </template>
        <template v-slot:item.desc="{ item }">
            {{ getShortDesc(item) }}
            <v-btn icon @click="showDescription(item)">
                <v-icon>mdi-message-text-outline</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.contactId="{ item }">
            <v-btn v-if="item.contactId" :to="{
                name: 'Contact View',
                params: {
                    contactId: item.contactId
                }
            }">
                <v-icon class="mr-2">
                    mdi-account-star-outline
                </v-icon>
                {{ getContactName(item.contactId) }}
            </v-btn>
        </template>
        <template v-slot:item.parentCompanyId="{ item }">
            <v-btn :to="{
                name: 'Company Dashboard',
                params:{
                    companyId: item.parentCompanyId
                }
            }">
                <v-icon class="mr-2">
                    mdi-office-building-outline
                </v-icon>
                <template v-if="!!getCompany(item.parentCompanyId)">
                    {{ getCompany(item.parentCompanyId).name }}
                </template>
            </v-btn>
        </template>
        <template v-slot:item.coffeeCupUserId="{ item }">
            <v-btn v-if="item.coffeeCupUserId && getUser(item.coffeeCupUserId)">
                <v-icon class="mr-2">
                    mdi-account-circle
                </v-icon>
                {{
                    getUserName(item.coffeeCupUserId)
                }}
            </v-btn>
        </template>
        <template v-slot:item.price="{ item }">
            <v-chip color="success">
                <v-icon class="mr-2">
                    mdi-currency-gbp
                </v-icon>
                {{ item.price }}
            </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn color="primary" small :to="{ name: 'Job View', params: { jobId: item.id } }"><v-icon
                    class="mr-2">mdi-magnify</v-icon>View Job</v-btn>
        </template>
    </trev-cool-table>

</template>

<script>
import trevCoolTable from '../../../../components/tables/trev-cool-table.vue'
import * as jobUtils from '../../../../utilities/Object Utilities/Jobs'
export default {
    components: { trevCoolTable },
    methods: {
        getStatusColour(status) {
            return jobUtils.getStatusColour(status.status);
        },
        getShortDesc(item) {
            return item.description.substring(0, 20) + '...'
        },
        getCompany(companyId) {
            return this.companiesInTable.find(x => x.id == companyId);
        },
        getContactName(contactId) {
            var c = this.getContact(contactId);

            var name = '';

            if (c && c.firstName) {
                name += c.firstName + ' ';
            }

            if (c && c.lastName) {
                name += c.lastName;
            }

            return name;
        },
        getUserName(uId) {
            console.log(uId)
            var user = this.usersInTable.find(x => x.id == uId);

            var name = '';

            if (user && user.firstName) {
                name += user.firstName + ' ';
            }

            if (user && user.lastName) {
                name += user.lastName;
            }

            return name;
        },
        loadedData(data) {
            this.contactsInTable = [];
            this.usersInTable = [];
            this.companiesInTable = [];

            for (const row of data) {
                if (row.contactId && !this.contactsInTable.some(x => x.id == row.contactId)) {
                    this.$courier.Contacts.getById(row.contactId).then(contact => {
                        this.contactsInTable.push(contact);
                    });
                }

                if (row.coffeeCupUserId && !this.usersInTable.some(x => x.id == row.coffeeCupUserId)) {
                    this.$courier.SystemUsers.getById(row.coffeeCupUserId).then(user => {
                        this.usersInTable.push(user);
                    })
                }

                if (!this.companiesInTable.some(x => x.id == row.parentCompanyId)) {
                    this.$courier.Companies.getById(row.parentCompanyId).then(company => {
                        this.companiesInTable.push(company);
                    });
                }
            }
        },
        getContact(contactId) {
            return this.contactsInTable.find(x => x.id == contactId);
        },
        getUser(userId) {
            return this.usersInTable.find(x => x.id == userId);
        },
        showDescription(item) {
            this.showingJob = item;
            this.showingDescription = true;
        },
    },
    data() {
        return {
            contactsInTable: [],
            usersInTable: [],
            companiesInTable: [],
            showingJob: {},
            showingDescription: false,
            selectedStatus: '',
            headers: [
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'PO Number',
                    value: 'poNumber'
                }, {
                    text: 'Title',
                    value: 'title'
                }, {
                    text: 'Description',
                    value: 'desc'
                }, {
                    text: 'Contact',
                    value: 'contactId'
                }, {
                    text: 'Project Manager',
                    value: 'coffeeCupUserId'
                }, {
                    text: 'Company',
                    value: 'parentCompanyId'
                },
                {
                    text: 'Price',
                    value: 'price'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                }]
        }
    },
}
</script>

<style>

</style> -->